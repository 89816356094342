import { useContext, useEffect } from 'react';
import React, { useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import AlumniProfileList from './AlumniProfileList';
import AlumniProfileListExtra from './AlumniProfileListExtra';
import { axiosClient } from '../../../library/Service/axiosClient';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import SessionContext from '../../../library/Core/SessionContext';
import { addUserSpecificDomains } from '../../../library/Service/domainService';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { GlobalContext } from '../../../library/Core/GlobalContext';
//import { State as SmartTypesState } from '../../../library/Core/SmartTypes'; // Assuming SmartTypes is correctly imported
import smartReducer from '../../../library/Core/SmartReducer';
// other imports

interface Errors {
    largeHeading?: string;
    mediumHeading?: string;
    gridLayout?: string;
}

// interface State {
//     formConfig: any; // Replace with actual type if available
//     data: any; // Replace with actual type if available
//     internal: any; // Replace with actual type if available
//     routeInfo: any; // Replace with actual type if available
// }
// interface State {
//     formConfig: any;  // Make it optional
//     data: any;
//     internal: any;
//     routeInfo: any;
//     pageDomain: any;
//     filters: any;
//     formValidationErrors: any;
//     actions: any;
// }
const GuidingAlumniList = () => {
    const { id, code } = useParams();
    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;
    console.log(id, 'id')
    const navigate = useNavigate();
    const location = useLocation();
    const [largeHeading, setLargeHeading] = useState('');
    const [mediumHeading, setMediumHeading] = useState('');
    const [gridLayout, setGridLayout] = useState('2');
    const [widgetHead, setwidgetHead] = useState('Guiding Alumni');
    const [widgetCode, setWidgetCode] = useState(code);
    const [widgetInstituteId, setWidgetInstituteId] = useState(id ? id : '');
    const [errors, setErrors] = useState<Errors>({});
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [count, setCount] = useState(0); // replace this with actual count logic
    const { globalState } = useContext(GlobalContext);
    const [schoolName, setSchoolName] = useState('');
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},        
    } as any);

    // useEffect(() => {
    //     console.log(sessionState, 'sessionState')
    //     const institute = sessionState;
    //     if (institute && Object.keys(institute).length > 1) {
    //         const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
    //         dispatch({
    //             type: 'DATA_INIT',
    //             payload: { formConfig, data, domain, routeInfo },
    //         });

    //         let schoolNamedata;

    //         if (institute.instituteGroupId == institute.primaryInstitute) {
    //             // If the user is logged into the primary institute
    //             schoolNamedata = getDomainValueForCode(
    //                 '10000005493', 
    //                 'INSTITUTE_DOMAIN', 
    //                 state
    //             );
    //         } else {
    //             // If the user is logged into a different institute within the same group
    //             console.log(institute.instituteGroupId, 'institute.instituteGroupId');
                
    //             // schoolNamedata = getDomainValueForCode(
    //             //     '10000000013', 
    //             //     'SCHOOL_GROUP', 
    //             //     state
    //             // );

    //             schoolNamedata = getDomainValueForCode(
    //                 '10000005493', 
    //                 'INSTITUTE_DOMAIN', 
    //                 state
    //             );
                
    //             console.log(schoolNamedata, ' ================ schoolName value based on user login');
    //         }
            
    //     }
    // }, [sessionState]);

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        console.log(sessionState, 'sessionState');
    
        const institutes = sessionState?.institutes || [];
        const instituteGroupId = sessionState?.instituteGroupId || '';
        
        let instituteName = '';
    
        if (state && state.domain && getDomainValueForCode) {  // Ensure that state and the function are defined
            if (!institutes.includes(String(instituteGroupId))) {
                console.log("Group School Detected");
                instituteName = getDomainValueForCode(instituteGroupId, 'SCHOOL_GROUP', state);
                setSchoolName(instituteName);
            } else {
                console.log("Single School Detected");
                const primaryInstitute = sessionState?.primaryInstitute || '';
                instituteName = getDomainValueForCode(primaryInstitute, 'INSTITUTE_DOMAIN', state);
                setSchoolName(instituteName);
            }
        } else {
            console.warn("State or getDomainValueForCode is not ready yet.");
        }
    
        console.log("Institute Name:", instituteName);
        
        console.log(schoolName, 'schoolName')
    
    }, [sessionState, state]);

    useEffect(() => {
        console.log(schoolName, 'schoolName updated');
        console.log(data?.countData?.widgetData[0]?.large_heading , 'large_heading')
        
        // console.log(data?.countData?.widgetData , 'wg data')
        // console.log(data?.countData?.widgetData[0]?.large_heading , 'large_heading')
        // console.log(data?.countData?.widgetWiseListData , 'wg widgetWiseListData')
        if ( code == 'notable_alumni_mentors' ) {
            setwidgetHead('Notable Alumni')
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
            } else {
                setLargeHeading(`${schoolName} Success Stories`)
                setMediumHeading('Here are some notable alumni who are out there making their dreams come true and making us proud too!')                
            }

            
        } else if ( code == 'alumni-speak' ) {
            setwidgetHead('Alumni Speak')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
                setGridLayout(String(data?.countData?.widgetData[0]?.grid_layout));
            } else {

                setLargeHeading(`${schoolName} Alumni Speak`)
                setMediumHeading('Here is what our distinguished alumni have to say about their experience while studying at the institute.')
            }
           
        } else if ( code == 'guiding_alumni_mentors' ) {
            setwidgetHead('Guiding Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
            } else {

                setLargeHeading(`${schoolName} Guiding Alumni`);
                setMediumHeading('We are proud of our alumni who are setting and example and motivating younger students. Here are some alumni who are actively guiding current students by answering questions, conducting webinars and much more.')
            }
           
        } else if ( code == 'eclectic_career_alumni' ) {
            setwidgetHead('Eclectic Career Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
            } else {

                setLargeHeading(`Eclectic career Chosen by Our Alumni`);
                setMediumHeading('Here are some alumni who are breaking the societal norms to pursue their dreams and making us proud too!')
            }
           
        } else if ( code == 'entrepreneur_alumni' ) {
            setwidgetHead('Entrepreneur Alumni')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
            } else {

                setLargeHeading(`${schoolName} Alumni as Entrepreneurs`);
                setMediumHeading('Here are some entrepreneur alumni who are out there making their dreams come true and making us proud too!')
            }
           
        } else if ( code == 'spotlight' ) {
            setwidgetHead('Alumni in Spotlight')
            
            if ( data?.countData?.success == true  ) {
                setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
                //setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
            } else {

                setLargeHeading(`Alumni in Spotlight`);
                //setMediumHeading('Here are some entrepreneur alumni who are out there making their dreams come true and making us proud too!')
            }
           
        }

        setCount(data?.countData?.widgetWiseListData?.length)
    }, [schoolName]);
    
    const handlePreviewClick = () => {
        // Example validation logic for count
        if ( code == 'spotlight' ) {

            console.log(count, 'data?.countData?.widgetWiseListData?');
            if (count > 0 && count <= 4) {
                // Redirect to the preview URL
                const previewUrl = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${id}`;
                // Open the preview URL in a new tab
                window.open(previewUrl, '_blank');;
            } else {
                // Handle the validation failure, e.g., show an alert or message
                alert('Count must be minimum 1 and maximum 4 to preview.');
            }

        } else {

            console.log(count, 'data?.countData?.widgetWiseListData?');
            if (count > 3) {
                // Redirect to the preview URL
                const previewUrl = `${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${id}`;
                // Open the preview URL in a new tab
                window.open(previewUrl, '_blank');;
            } else {
                // Handle the validation failure, e.g., show an alert or message
                alert('Count must be greater than 4 to preview.');
            }

        }


        
    };

    // useEffect(() => {
    //     console.log(data?.countData?.widgetData[0]?.large_heading , 'large_heading')
        
    //     // console.log(data?.countData?.widgetData , 'wg data')
    //     // console.log(data?.countData?.widgetData[0]?.large_heading , 'large_heading')
    //     // console.log(data?.countData?.widgetWiseListData , 'wg widgetWiseListData')
    //     if ( code == 'notable_alumni_mentors' ) {
    //         setwidgetHead('Notable Alumni')
    //         if ( data?.countData?.success == true  ) {
    //             setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
    //             setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
    //         } else {
    //             setLargeHeading(`${schoolName} Success Stories`)
    //             setMediumHeading('Here are some notable alumni who are out there making their dreams come true and making us proud too!')                
    //         }

            
    //     } else if ( code == 'alumni-speak' ) {
    //         setwidgetHead('Alumni Speak')
            
    //         if ( data?.countData?.success == true  ) {
    //             setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
    //             setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
    //         } else {

    //             setLargeHeading(`${schoolName} Alumni Speak`)
    //             setMediumHeading('Here is what our distinguished alumni have to say about their experience while studying at the institute.')
    //         }
           
    //     } else if ( code == 'guiding_alumni_mentors' ) {
    //         setwidgetHead('Guiding Alumni')
            
    //         if ( data?.countData?.success == true  ) {
    //             setLargeHeading(data?.countData?.widgetData[0]?.large_heading)
    //             setMediumHeading(data?.countData?.widgetData[0]?.medium_heading)
    //         } else {

    //             setLargeHeading(`${schoolName} Guiding Alumni`);
    //             setMediumHeading('We are proud of our alumni who are setting and example and motivating younger students. Here are some alumni who are actively guiding current students by answering questions, conducting webinars and much more.')
    //         }
           
    //     }

    //     setCount(data?.countData?.widgetWiseListData?.length)
        
    // }, []);

    

    const handleValidation = () => {
        let tempErrors: Errors = {};
        let isValid = true;

        // Constants for maximum lengths
        const maxLargeHeadingLength = 60;
        const maxMediumHeadingLength = 250;
        if (!largeHeading) {
            tempErrors.largeHeading = "Large Heading is required";
            isValid = false;
        } else if (largeHeading.trim() === "") {
            tempErrors.largeHeading = "Large Heading cannot be empty or whitespace";
            isValid = false;
        } else if (largeHeading.length > maxLargeHeadingLength) {
            tempErrors.largeHeading = `Large Heading cannot exceed ${maxLargeHeadingLength} characters`;
            isValid = false;
        }
        // Validate medium heading only if the widget code is not 'spotlight'
        if (code !== 'spotlight') {
            if (!mediumHeading) {
                tempErrors.mediumHeading = "Medium Heading is required";
                isValid = false;
            } else if (mediumHeading.trim() === "") {
                tempErrors.mediumHeading = "Medium Heading cannot be empty or whitespace";
                isValid = false;
            } else if (mediumHeading.length > maxMediumHeadingLength) {
                tempErrors.mediumHeading = `Medium Heading cannot exceed ${maxMediumHeadingLength} characters`;
                isValid = false;
            }
        }

        setErrors(tempErrors);
        return isValid;
    };
    const handleGridLayoutChange = (e:any) => {
        setGridLayout(e.target.value);
        // Additional validation or state updates can be added here
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (handleValidation()) {
            try {
                const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/action/savewidget`, {
                    largeHeading,
                    mediumHeading,
                    gridLayout,
                    widgetCode,
                    widgetInstituteId
                });
    
                // Axios automatically parses JSON response
                console.log('Success:', response.data.id);
                alert('added successfully')
                 // Redirect to the desired URL after success
                const newUrl = `${process.env.REACT_APP_APP_BASE_URL}/widget/configurelist/${widgetCode}/${response.data.id}`;
                window.location.href = newUrl;
                // You can add more actions on success, e.g., reset form, show a success message, etc.
            } catch (error: any) {
                if (error.response) {
                    // Server responded with a status other than 200 range
                    console.error('Response error:', error.response.data);
                    alert('Server returned an error response.');
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('No response received:', error.request);
                    alert('No response received from the server.');
                } else {
                    // Something else caused the error
                    console.error('Error:', error.message);
                    alert('An error occurred. Please try again later.');
                }
            }
        }
    };

    return (
        <>
        <div className="main flex-1 bg-white">
            <div className="max-1140 mx-auto pt-5">
                <div className="d-flex justify-content-between align-items-center pb-3 ">
                    <h3 className="font-30 font-600 mb-0 block-title position-relative">
                        Manage Widgets <i className="icon-right-arrow font-16 position-relative me-1"></i> <span>{widgetHead}</span>
                    </h3>         
                </div>
            </div>

            <div className="py-5 bg-blue-164F88">
                <div className="max-1140 mx-auto">
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="bg-blue-083461 border-radius-6 px-4 py-3">
                                <div className="row g-3">
                                    <div className="col">
                                    <h3 className="text-white mb-0 font-24">{largeHeading}</h3>
                                    </div>
                                    <div className="col-auto">
                                    <label htmlFor="largeHeading" className="text-white cursor-pointer white-filter"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {code !== 'spotlight' && (
                        <div className="col-12">
                            <div className="bg-blue-083461 border-radius-6 px-4 py-3">
                                <div className="row g-3">
                                    <div className="col">
                                    <p className="text-white">{mediumHeading}</p>
                                    </div>
                                    <div className="col-auto">
                                    <label htmlFor="mediumHeading" className="text-white cursor-pointer white-filter"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='max-1140 py-5 overflow-hidden mx-auto'>
                <div className='row g-4'>
                <div className='col-12'>
                <form className='row g-4' onSubmit={handleSubmit}>
                    <div className="col-sm-6">
                        <label htmlFor="largeHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Large Heading</label>
                        <input type="text" className="form-control flex-1" id="largeHeading" placeholder="Enter Heading" value={largeHeading} onChange={(e) => setLargeHeading(e.target.value)} />
                        <span style={{ color: "red" }}>{errors.largeHeading}</span>
                    </div>
                    {code === 'alumni-speak' && (
                <div className="col-sm-6">
                    <label htmlFor="gridLayout" className="form-label m-0 mb-1 font-16 font-500 w-100">
                        Grid Layout
                    </label>
                    <div className="row g-3 align-items-center py-sm-2">
                        <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                
                                <input
                                    type="radio"
                                    name="gridLayout"
                                    id="row2"
                                    value="2"
                                    className="form-check-input custom-border-84-20 m-0"
                                    checked={gridLayout === '2'}
                                    onChange={handleGridLayoutChange}
                                />
                                <label htmlFor="row2" className="form-check-label font-16 font-500 ms-2">
                                    2 in a row
                                </label>
                            </div>
                        </div>
                        <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                <input
                                    type="radio"
                                    name="gridLayout"
                                    id="row3"
                                    value="3"
                                    className="form-check-input custom-border-84-20 m-0"
                                    checked={gridLayout === '3'}
                                    onChange={handleGridLayoutChange}
                                />
                                <label htmlFor="row3" className="form-check-label font-16 font-500 ms-2">
                                    3 in a row
                                </label>
                            </div>
                        </div>
                        <div className="col-auto col-sm-auto col-md-4 col-lg-4">
                            <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                <input
                                    type="radio"
                                    name="gridLayout"
                                    id="row4"
                                    value="4"
                                    className="form-check-input custom-border-84-20 m-0"
                                    checked={gridLayout === '4'}
                                    onChange={handleGridLayoutChange}
                                />
                                <label htmlFor="row4" className="form-check-label font-16 font-500 ms-2">
                                    4 in a row
                                </label>
                            </div>
                        </div>
                    </div>
                    <span style={{ color: "red" }}>{errors.gridLayout}</span>
                </div>
            )}
                     {code !== 'spotlight' && (
                    <div className="col-sm-12">
                        <label htmlFor="mediumHeading" className="form-label m-0 mb-1 font-16 font-500 w-100">Medium Heading</label>
                        <textarea className='form-control' id="mediumHeading" placeholder="Enter Heading" rows={3} value={mediumHeading} onChange={(e) => setMediumHeading(e.target.value)} />
                        <span style={{ color: "red" }}>{errors.mediumHeading}</span>
                    </div>
                     )}
                    <input type="hidden" className="form-control flex-1" id="widgetCode" placeholder="Enter Heading" value={widgetCode} />
                    
                    <div className='col-12'>
                        <button type="submit" className="btn btn-green height-45 px-5"><span className="px-3">Save</span></button>
                    </div>
                </form>
                </div>
                

                    <div className='col-sm-12'>
                    {code === 'alumni-speak' ? (
                        id ? <AlumniProfileListExtra/> : null
                    ) : (
                        id ? <AlumniProfileList/> : null
                    )}
                    
                    </div>

                    {id && (
                    <div className="col-sm-12">
                        <div className="action_btns mt-0 max-100per">
                        <button 
                    type="button" 
                    className="nextbtn my-2 my-sm-0 mx-2 px-5" 
                    onClick={handlePreviewClick}
                >
                    Preview
                </button>               
                            {/* <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5"><span className="px-3">Save </span></button> */}
                        </div>
                    </div>
                     )}
                </div>

                
            </div>
        </div>
        </>
    );
};

export default GuidingAlumniList;
