import { Navigate } from 'react-router-dom';
import { default as AlumniProfileEditLayout } from '../app/alumni/AlumniProfileEditLayout';
import BlankLayout from '../app/alumni/BlankLayout';
import StandardLayout from '../app/alumni/StandardLayout';
import ManagementLayout from '../app/management/ManagementLayout';
import Dashboardadmin from '../features/admin/dashboard/Dashboard';
import AdminLogin from '../features/admin/login/AdminLogin';
import EmailVerification from '../features/alumni/EmailVerification/EmailVerification';
import Dashboard from '../features/alumni/dashboard/Dashboard';
import DashboardService from '../features/alumni/dashboard/DashboardPageLoader';
import AlumniProfileImage from '../features/alumni/profile-edit/basic-info/AlumniProfileImage';
import StudentPreference from '../features/alumni/profile-edit/student-preference/StudentPreference';
import AlumniEmailVerification from '../features/alumni_email_verification/AlumniEmailVerification';
import PeerInvite from '../features/alumni_invite/invite';
import PeerReviewService from '../features/alumni_review/PeerReviewService';
import AdminPeerReview from '../features/alumni_review/admin_peer_review';
import PeerReview from '../features/alumni_review/peer_review';
import InstituteSelection from '../features/auth/institute-selection/InstituteSelection';
import Login from '../features/auth/login/Login';
import RedirectToUserLogin from '../features/auth/login/RedirectToUserLogin';
import AutoLogin from '../features/autologin/AutoLogin';
import AdminAdditionalInfo from '../features/management/admin-profile/edit/additional-info/AdminAdditionalInfo';
import AdminBasicInfo from '../features/management/admin-profile/edit/basic-info/AdminBasicInfo';
import AdminPreferences from '../features/management/admin-profile/edit/preferences/AdminPreferences';
import AdminProfile from '../features/management/admin-profile/view/AdminProfile';
import ManageAlumni from '../features/management/alumni-list/ManageAlumni';
import ManageAlumniService from '../features/management/alumni-list/ManageAlumniService';
import ManagementDashboard from '../features/management/dashboard/ManagementDashboard';
import ManagementDashboardService from '../features/management/dashboard/ManagementDashboardService';
import Home from '../features/management/home/Home';
import ManagementLogin from '../features/management/login/ManagementLogin';
import AlumniNotification from '../features/notification/AlumniNotification';
import Commerece from '../features/psychometric_test/careerGuide/Commerece';
import Engineering from '../features/psychometric_test/careerGuide/Engineering';
import Humanities from '../features/psychometric_test/careerGuide/Humanities';
import Idealcareertest from '../features/psychometric_test/careerGuide/Idealcareertest';
import Learningstyletest from '../features/psychometric_test/careerGuide/Learningstyletest';
import MultipleIntelligenceTest from '../features/psychometric_test/careerGuide/MultipleIntelligenceTest';
import PersonalityTest from '../features/psychometric_test/careerGuide/PersonalityTest';
import StreamSelectorTest from '../features/psychometric_test/careerGuide/SreamSelectorTest';
import StudentManagementReport from '../features/reports/student-management/StudentManagementReport';
import ShareMyProfile from '../features/share-profile/sharemyprofile';
//Added by Vikash
import Sendemail from '../features/broadcast/create-broadcast/sendemail';
import AddContent from '../features/broadcast/create-broadcast/AddContent';
import TemplatePreview from '../features/broadcast/email-preview/TemplatePreview';
import TemplatePreviewLater from '../features/broadcast/email-preview/TemplatePreviewLater';
import DraftMail from '../features/broadcast/draft/DraftMail';
import EmailPreview from '../features/broadcast/email-preview/preview';
import ManageStudent from '../features/broadcast/student/ManageAlumni';
import ScheduleMail from '../features/broadcast/draft/Schedulemail';
import SentMail from '../features/broadcast/draft/sentmail';
import PreviewLater from '../features/broadcast/email-preview/previewlater';
import ManageBroadcastLead from '../features/broadcast/lead-list/ManageLead';
import TemplateList from '../features/broadcast/email-template/TemplateList';
import GoogleReview from '../features/broadcast/draft/GoogleReview.tsx';
import AddGoogleReview from '../features/broadcast/email-preview/AddGoogleReview.tsx';
//Added by Vikash

import Screening from '../features/pre_signup/Screening';
import ScreeningInfo from '../features/pre_signup/ScreeningInfo';
import IdenetyUser from '../features/pre_signup/IdentifyUser';
import Parent from '../features/pre_signup/Parent';
import ParentChildInfo from '../features/pre_signup/ParentChildInfo';
import ThanksPage from '../features/pre_signup/ThanksPage';
import PastStudent from '../features/pre_signup/PastStudent';
//Above Added for pre Signup by Vikash

import Brosefile from '../features/lead/import-lead/browsefile';
//import AddLead from '../features/lead/add-lead/addnewlead';

import ManageLead from '../features/lead/view/ManageLead';
import ManageLeadService from '../features/lead/view/ManageLeadService';

import AddLeadInfo from '../features/lead/add-lead/addnewleadinfo';
import AddLeadProgram from '../features/lead/add-lead/addnewleadprogram';
import EditLeadInfo from '../features/lead/update-lead/leadinfo';
import TargetProgram from '../features/lead/update-lead/targetprogram';
import ViewProfile from '../features/lead/update-lead/viewprofile';
import UpdateProgram from '../features/lead/update-lead/UpdateProgram';

import StudentAchievement from '../features/student/edit/achievement/StudentAchievement';
import StudentActivity from '../features/student/edit/activity/StudentActivity';
import StudentBasicInfo from '../features/student/edit/basic-info/StudentBasicInfo';
import StudentBooks from '../features/student/edit/books/StudentBooks';
import Education from '../features/student/edit/education/Education';
import EntranceExam from '../features/student/edit/entrance-exams/EntranceExam';
import Parents from '../features/student/edit/parents/Parents';
import StudentSignup from '../features/student/signup/StudentSignup';
import StudentProfile from '../features/student/view/StudentProfile';
import EducationDetails from '../features/student/view/sections/EducationDetails';
import EntranceExams from '../features/student/view/sections/EntranceExams';
import ExtraCurricular from '../features/student/view/sections/ExtraCurricular';
import ParentInformation from '../features/student/view/sections/ParentInformation';
import Preferences from '../features/student/view/sections/Preferences';
import pageLoader from '../library/Core/PageLoader';
import ErrorPage from '../library/SimpleControls/ErrorPage';
import StudentService from '../services/student.service';
//import AutoLogin from '../features/autologin/AutoLogin';
//Added By Seerish
import AlumniMatchStudents from '../features/admin/alumni_match_students/AlumniMatchStudents';
import AlumniMatchStudentsService from '../features/admin/alumni_match_students/AlumniMatchStudentsService';
import AlumniSchoolSupport from '../features/admin/alumni_school/AlumniSchoolSupport';
import AlumniSchoolSupportService from '../features/admin/alumni_school/AlumniSchoolSupportService';
import CreateNewAlumni from '../features/admin/create_new_alumni/CreateNewAlumni';
import CreateNewAlumniService from '../features/admin/create_new_alumni/CreateNewAlumniService';
import Schooltestmapping from '../features/admin/dashboard/Schooltestmapping';
import ManageAlumniEngage from '../features/management/alumni-list/ManageAlumniEngage';
import ManageAlumniServiceEngage from '../features/management/alumni-list/ManageAlumniServiceEngage';

//Event links
//Added By Seerish
import AddNew from '../features/eventmanage/add/AddNew';
import AddNewService from '../features/eventmanage/add/AddnewService';
//import BasicDetails from '../features/eventmanage/add/BasicDetails'
import SpeakerInfo from '../features/eventmanage/add/SpeakerInfo';
import SpeakerInfoService from '../features/eventmanage/add/SpeakerInfoService';
import AudienceCategory from '../features/eventmanage/add/AudienceCategory';
import AudienceCategoryService from '../features/eventmanage/add/AudienceCategoryService';
import ThemeOptions from '../features/eventmanage/add/ThemeOptions';
import ThemeOptionsService from '../features/eventmanage/add/ThemeOptionsService';
import ManageEvent from '../features/eventmanage/view/ManageEvent';
import ManageEventService from '../features/eventmanage/view/ManageEventService';

//ADDED By Seerish - Social post links

import ManageSocialIntegrations from '../features/socialsharing/ManageSocialIntegrations';
import SocialMedia from '../features/socialsharing/SocialMedia';
import SocialPost from '../features/socialsharing/SocialPost';

//added by vrinda
import EngagementLayout from '../app/admin/EngagementLayout';
import AlumniConversions from '../features/admin/engagement-list/AlumniConversions';
import AlumniConversionsService from '../features/admin/engagement-list/AlumniConversionsService';
import AlumniEngagement from '../features/admin/engagement-list/AlumniEngagement';
import AlumniEngagementService from '../features/admin/engagement-list/AlumniEngagementService';
import AlumniSelectedConversions from '../features/admin/engagement-list/AlumniSelectedConversions';
import InternalAdminReport from '../features/admin/reports/InternalAdminReport';
import InternalAdminReportService from '../features/admin/reports/InternalAdminReportService';
// Added by Irfan shaikh
import Contact from '../features/admin/contact/Contact';
import ContactService from '../features/admin/contact/ContactService';
import DomainCategoryDataService from '../features/admin/domain-data/DomainCategoryDataService';
import DomainDetail from '../features/admin/domain-data/DomainDetail';
import Institute from '../features/admin/institute/Institute';
import InstituteLisingService from '../features/admin/institute/InstituteLisingService';
import InstituteListing from '../features/admin/institute/InstituteListing';
import InstituteService from '../features/admin/institute/InstituteService';
import CreateinstituteProgram from '../features/institute-program/CreateinstituteProgram';
import InstituteProgramService from '../features/institute-program/InstituteProgramService';
import ProgramLisingService from '../features/institute-program/ProgramLisingService';
import ProgramListing from '../features/institute-program/ProgramListing';
import SchoolApi from '../features/mainadmin/SchoolApi';
import SchoolApiService from '../features/mainadmin/SchoolApiService';
import { alumniRoutes } from './alumni-routes';

// Seerish  on 20-05-2024

import AddGuidingAlumni from '../features/widgets/alumni_guiding/AddGuidingAlumni';
import GuidingAlumniPreview from '../features/widgets/alumni_guiding/GuidingAlumniPreview';
import GuidingAlumniPreviewFrontend from '../features/widgets/alumni_guiding/GuidingAlumniPreviewFrontend';
import ManageWidgetsList from '../features/widgets/ManageWidgetsList';
import ManageWidgetsListService from '../features/widgets/ManageWidgetsListService';
import WidgetService from '../features/widgets/WidgetService';
import WidgetConfiguration from '../features/widgets/WidgetConfiguration';
import AddGuidingAlumniList from '../features/widgets/alumni_guiding/AddGuidingAlumniList';
import WidgetServiceList from '../features/widgets/WidgetServiceList';
import WidgetConfigurationList from '../features/widgets/WidgetConfigurationList';

// Added By Irfan Shaikh
// Date : 20-05-2024
// Purpose : Colleges Tools
import TopColleges from '../features/top-colleges/TopColleges';
import CollegeService from '../features/top-colleges/CollegeService';
import RedirectToUserSignup from '../features/auth/login/RedirectToUserSignup';
import ManageWidgetsAlumni from '../features/widgets/ManageWidgetsAlumni';
import HandleLinkedInCallback from '../features/admin/login/HandleLinkedInCallback';
import HandleGoogleCallback from '../features/admin/login/HandleGoogleCallback';
import SignupSocial from '../features/admin/login/SignupSocial';

import Configuration from '../features/widgets/Configuration';
import ProfileMarkerNotesService from '../features/admin/Profile-marker-notes/ProfileMarkerNotesService';
import ProfileMarkerNotes from '../features/admin/Profile-marker-notes/ProfileMarkerNotes';

import StudentDashboard from '../features/qna/StudentDashboard';
import QnaApiService from '../features/qna/QnaApiService';

const schoolDomainFromLocalStorage = JSON.parse(localStorage.getItem('school_settings'))?.schoolDomain ?? '';

const routes = [
    alumniRoutes,
    {
        path: '/',
        errorElement: <ErrorPage />,
        element: <Navigate to={`/alumni/${schoolDomainFromLocalStorage}/login`} replace />,
    },
    {
        path: '/handle-linkedin-login',
        errorElement: <ErrorPage />,
        element: <HandleLinkedInCallback pageTitle="LinkedIn Login"/>
    },
    {
        path: '/handle-google-login',
        errorElement: <ErrorPage />,
        element: <HandleGoogleCallback pageTitle="Google Login"/>
    },
    {
        path: '/social-signup',
        errorElement: <ErrorPage />,
        loader: async ({ params }) => {
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <SignupSocial pageTitle="Signup"/>
    },
    {
        path: '/select-institute',
        errorElement: <ErrorPage />,
        element: <InstituteSelection pageTitle="Choose the Network" />,
    },

    {
        path: 'user/:schoolDomain?/login/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Welcome to Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-login' });
        },
        element: <Login />,
    },
    {
        path: 'user/:schoolDomain?/signup/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Join Your Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <Login />,
    },
    {
        path: '/alumni/:schoolDomain?/login/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Welcome to Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-login' });
        },
        element: <RedirectToUserLogin />,
    },
    {
        path: '/student/:schoolDomain?/login/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Welcome to Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-login' });
        },
        element: <RedirectToUserLogin />,
    },

    {
        path: 'alumni/:schoolDomain?/signup/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Join Your Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <RedirectToUserSignup />,
    },
    {
        path: 'student/:schoolDomain?/signup/:invitedBy?',
        loader: async ({ params }) => {
            document.title = 'Join Your Alumni Network';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <RedirectToUserSignup />,
    },
    // {
    //     path: '/student/:schoolDomain',
    //     children: [
    //         {
    //             path: 'signup',
    //             loader: async ({ params }) => {
    //                 return pageLoader({ ...params, pageName: 'student-signup' });
    //             },
    //             element: <StudentSignup />,
    //         },
    //         {
    //             path: 'login',
    //             loader: async ({ params }) => {
    //                 return pageLoader({ ...params, pageName: 'student-login' });
    //             },
    //             element: <StudentSignup />,
    //         },
    //     ],
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/admin',
        children: [
            {
                path: 'login',
                element: <AdminLogin pageTitle="Univariety" />,
            },
            {
                path: 'dashboard',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Dashboardadmin />,
            },
            {
                path: 'Schooltestmapping',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Schooltestmapping />,
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: '/psychometric_test',
        children: [
            {
                path: 'careerGuide/StreamSelectorTest',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <StreamSelectorTest />,
            },
            {
                path: 'careerGuide/PersonalityTest',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <PersonalityTest />,
            },
            {
                path: 'careerGuide/MultipleIntelligenceTest',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <MultipleIntelligenceTest />,
            },
            {
                path: 'careerGuide/Learningstyletest',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Learningstyletest />,
            },
            {
                path: 'careerGuide/Idealcareertest',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Idealcareertest />,
            },
            {
                path: 'careerGuide/Humanities',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Humanities />,
            },
            {
                path: 'careerGuide/Engineering',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Engineering />,
            },
            {
                path: 'careerGuide/Commerece',
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'student-signup' });
                },
                element: <Commerece />,
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: '/management/:schoolDomain',
        children: [
            {
                path: 'login',
                loader: async ({ params }) => {
                    document.title = 'Manage Your Student and Alumni Community';
                    return pageLoader({ ...params, pageName: 'school-management-login' });
                },
                element: <ManagementLogin />,
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: '/admin/qna-dashboard',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                loader: async ({ params }) => {
                    document.title = 'Student QNA';
                    return QnaApiService.pageLoader({ ...params, pageName: 'qna' });
                },
                element: <StudentDashboard /> 
            }
        ],
    },
    {
        path: '/schoolApiKey',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'api-key',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return SchoolApiService.pageLoader({ ...params, pageName: 'support-alumni-school' });
                },
                element: <SchoolApi key="support-alumni-school" />,
            },
        ],
    },
    {
        path: 'student/dashboard',
        loader: async ({ params }) => {
            return DashboardService.pageLoader({ ...params, pageName: 'alumni-dashboard' });
        },
        element: <Dashboard />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/student/:id/profile',
        errorElement: <ErrorPage />,
        element: (
            <>
                {/* <header>
                    <HeaderControl />
                    <MenuBuilder />
                </header> */}

                <StudentProfile key="ALUMNI_PROFILE" />
            </>
        ),
        loader: async ({ params }) => {
            return StudentService.pageLoader({ ...params, pageName: 'student-profile', includeAdditionalInfo: true });
        },
        children: [
            { path: '', element: <Preferences /> },
            { path: 'preference', element: <Preferences /> },
            { path: 'parent-info', element: <ParentInformation /> },
            { path: 'education', element: <EducationDetails /> },
            { path: 'entrance-exams', element: <EntranceExams /> },
            { path: 'extra-curricular', element: <ExtraCurricular /> },
        ],
    },
    {
        path: '/student/:id',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'info',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-basic-info' });
                },
                element: <StudentBasicInfo />,
            },
            {
                path: 'education',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-education' });
                },
                element: <Education />,
            },
            {
                path: 'entrance-exam',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-entrance-exams' });
                },
                element: <EntranceExam />,
            },
            {
                path: 'achievements',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-achievements', includeAdditionalInfo: true });
                },
                element: <StudentAchievement />,
            },
            {
                path: 'books',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-reading-interest', includeAdditionalInfo: true });
                },
                element: <StudentBooks />,
            },
            {
                path: 'activity',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-activity', includeAdditionalInfo: true });
                },
                element: <StudentActivity />,
            },
            {
                path: 'parent-info',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-parents', includeAdditionalInfo: true });
                },
                element: <Parents />,
            },
            {
                path: 'student-preference',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({ ...params, pageName: 'student-education-preferences', includeAdditionalInfo: true });
                },
                element: <StudentPreference />,
            },
            {
                path: 'profile-picture',
                loader: async ({ params }) => {
                    return StudentService.pageLoader({
                        ...params,
                        pageName: 'alumni-profile-picture',
                        includeAdditionalInfo: true,
                    });
                },
                element: <AlumniProfileImage />,
            },
        ],
    },

    {
        path: '/alumni-invite',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'peer',
                element: <PeerInvite pageTitle="Send Invites" />,
            },
        ],
    },
    {
        path: '/socialmedia',
        element: <ManagementLayout />,
        children: [
            {
                path: 'socialintegration',
                element: <ManageSocialIntegrations />,
            },
            {
                path: 'socialpage',
                element: <SocialMedia />,
            },
            {
                path: 'socialpost',
                element: <SocialPost />,
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: '/share-profile',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <ShareMyProfile />,
            },
        ],
    },
    {
        path: '/broadcast/ask-google-review',       
        element: <GoogleReview pageTitle="Email TemplatAsk Google Review"/>
    },
    {
        path: '/broadcast/add-google-review',
        element: <AddGoogleReview pageTitle="Email TemplatAsk Google Review"/>
    },
    {
        path: '/broadcast',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'createnew',
                element: <Sendemail pageTitle="Broadcast" />,
            },
            {
                path: 'addcontent',
                element: <AddContent pageTitle="Broadcast" />,
            },
            {
                path: 'templatepreview',
                element: <TemplatePreview pageTitle="Broadcast" />,
            },
            {
                path: 'template-preview-later',
                element: <TemplatePreviewLater pageTitle="Broadcast" />,
            },
            {
                path: 'student-profiles',
                loader: async ({ params }) => {
                    document.title="Student Management"
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-student-profiles' });
                },
                element: <ManageStudent key="track-student-profiles" />,
            },
            {
                path: 'email-templates',
                errorElement: <ErrorPage />,
                loader: async ({ params }) => {
                    return pageLoader({ ...params, pageName: 'alumni-signup' });
                },
                element: <TemplateList pageTitle="Email Template"/>
            },
            {
                path: 'alumni-profiles',
                loader: async ({ params }) => {
                    document.title="Alumni Management"
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-alumni-profiles' });
                },
                element: <ManageStudent key="track-alumni-profiles" />,
            },
            { 
                path: 'emailpreview',
                element: <EmailPreview />,
            },
            {
                path: 'preview-later',
                element: <PreviewLater />,
            },
            {
                path: '',
                element: <DraftMail pageTitle="Broadcast" />,
            },
            {
                path: 'sent-mail',
                element: <SentMail pageTitle="Broadcast" />,
            },
            {
                path: 'schedule-mail',
                element: <ScheduleMail pageTitle="Broadcast" />,
            },
            {
                path: 'lead-profiles',
                loader: async ({ params }) => {
                    return ManageLeadService.pageLoader({ ...params, pageName: 'manage-lead' });
                },
                element: <ManageBroadcastLead key="manage-lead" />,
            },
        ],
    },

    {
        path: 'screening/:schoolDomain?/signup',
        loader: async ({ params }) => {
            document.title = 'Screening';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <Screening />,
    },
    {
        path: 'screening/:schoolDomain?/info',
        loader: async ({ params }) => {
            document.title = 'Screening';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <ScreeningInfo />,
    },
    {
        path: 'screening/:schoolDomain?/identfyuser',
        loader: async ({ params }) => {
            document.title = 'Screening';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <IdenetyUser />,
    },
    {
        path: 'screening/:schoolDomain?/parent',
        loader: async ({ params }) => {
            document.title = 'Parent Form';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <Parent />,
    },
    {
        path: 'screening/:schoolDomain?/parent-child-info',
        loader: async ({ params }) => {
            document.title = 'Parent Form';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <ParentChildInfo />,
    },
    {
        path: 'screening/:schoolDomain?/past-student',
        loader: async ({ params }) => {
            document.title = 'Student Form';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <PastStudent />,
    },
    {
        path: 'screening/:schoolDomain?/thank-you',
        loader: async ({ params }) => {
            document.title = 'Thanks';
            return pageLoader({ ...params, pageName: 'alumni-signup' });
        },
        element: <ThanksPage />,
    },
   
    {
        path: '/lead',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'browsefile',
                element: <Brosefile />,
            },
            {
                path: 'add-personal-info',
                element: <AddLeadInfo />,
            },
            {
                path: 'add-target-program',
                element: <AddLeadProgram />,
            },
            {
                path: 'ManageLead',
                // loader: async ({ params }) => {
                //     return ProgramLisingService.pageLoader({
                //         ...params,
                //         pageName: 'program-list',
                //     });
                // },
                // element: <ProgramListing key="program-list" />,

                loader: async ({ params }) => {
                    document.title = 'Lead Management';
                    return ManageLeadService.pageLoader({ ...params, pageName: 'manage-lead' });
                },
                element: <ManageLead key="manage-lead" />,
            },
            {
                path: 'update-personal-info',
                element: <EditLeadInfo />,
            },
            {
                path: 'update-target-program',
                element: <TargetProgram />,
            },
            {
                path: 'lead-profile',
                element: <ViewProfile />,
            },
            {
                path: 'update-lead-program',
                element: <UpdateProgram />,
            },
        ],
    },
    {
        path: '/alumni-review',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'peer',
                loader: async ({ params }) => {
                    document.title = 'Review Peer Profiles';
                    return PeerReviewService.pageLoader({ ...params, pageName: 'alumni-review', searchBy: 'default' });
                },
                element: <PeerReview />,
            },
            {
                path: 'admin',
                loader: async ({ params }) => {
                    document.title = 'Review Profiles';
                    return PeerReviewService.pageLoader({ ...params, pageName: 'alumni-review', searchBy: 'default' });
                },
                element: <AdminPeerReview />,
            },
        ],
    },
    {
        path: '/email-verify',
        element: <AlumniProfileEditLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ':id',
                element: <EmailVerification />,
            },
        ],
    },
    {
        path: '/event',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'manageevent',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return ManageEventService.pageLoader({ ...params, pageName: 'manage-event' });
                },
                element: <ManageEvent key="manage-event" />,
            },
            {
                path: 'addnew',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return AddNewService.pageLoader({ ...params, pageName: 'add-event' });
                },
                element: <AddNew />,
            },
            {
                path: 'speaker-info',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return SpeakerInfoService.pageLoader({ ...params, pageName: 'update-event-speaker' });
                },
                element: <SpeakerInfo />,
            },
            {
                path: 'audience-category',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return AudienceCategoryService.pageLoader({ ...params, pageName: 'update-audience' });
                },
                element: <AudienceCategory />,
            },
            {
                path: 'theme-options',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return ThemeOptionsService.pageLoader({ ...params, pageName: 'update-theme' });
                },
                element: <ThemeOptions />,
            },
        ],
    },
    {
        path: '/management',
        element: <ManagementLayout />,
        //element: <ProtectedRoute isAuthenticated={App.isAuthenticated} component={ManagementLayout} />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'home',
                element: <Home />,
                //element: <ProtectedRoute isAuthenticated={App.isAuthenticated} component={Home} />,
            },
            // {
            //     path: 'student-listing',
            //     loader: async ({ params }) => {
            //         return StudentListService.pageLoader({ ...params, pageName: 'manage-students' });
            //     },
            //     element: <ManageStudents />,
            //     //element: <ProtectedRoute isAuthenticated={App.isAuthenticated} component={ManageStudents} />,
            // },
            {
                path: 'dashboard',
                loader: async ({ params }) => {
                    document.title = 'View Dashboard';
                    return ManagementDashboardService.pageLoader({ ...params, pageName: 'management-dashboard' });
                },
                element: <ManagementDashboard />,
                //element: <ProtectedRoute isAuthenticated={App.isAuthenticated} component={ManagementDashboard} />,
            },
            // {
            //     path: 'manage-alumni',
            //     loader: async ({ params }) => {
            //         return ManageAlumniService.pageLoader({ ...params, pageName: 'manage-alumni' });
            //     },
            //     element: <ManageAlumni />,
            // },
        ],
    },
    {
        path: '/admin',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'testbyschool',
                loader: async ({ params }) => {
                    return PeerReviewService.pageLoader({ ...params, pageName: 'alumni-review', searchBy: 'default' });
                },
                element: <AdminPeerReview />,
            },
        ],
    },
    // {
    //     path: '/management/:id/profile',
    //     loader: async ({ params }) => {
    //         return StudentService.pageLoader({ ...params, pageName: 'admin-profile' });
    //     },
    //     element: <AdminProfile />,
    //     errorElement: <ErrorPage />,
    // },
    {
        path: '/management/:id',
        // loader: async ({ params }) => {
        //     return StudentService.pageLoader({ ...params, pageName: 'student-profile' });
        // },
        element: <ManagementLayout />,
        children: [
            {
                path: 'basic-info',
                loader: async ({ params }) => {
                    document.title = 'Update Basic Information';
                    return StudentService.pageLoader({ ...params, pageName: 'admin-basic-info' });
                },
                element: <AdminBasicInfo />,
            },
            {
                path: 'additional-info',
                loader: async ({ params }) => {
                    document.title = 'Management Profile';
                    return StudentService.pageLoader({ ...params, pageName: 'admin-additional-info' });
                },
                element: <AdminAdditionalInfo />,
            },
            {
                path: 'preferences',
                loader: async ({ params }) => {
                    document.title = 'Management Profile';
                    return StudentService.pageLoader({ ...params, pageName: 'admin-preferences' });
                },
                element: <AdminPreferences />,
            },
            {
                path: 'profile',
                loader: async ({ params }) => {
                    document.title = 'Management Profile';
                    return StudentService.pageLoader({ ...params, pageName: 'admin-profile' });
                },
                element: <AdminProfile />,
            },
        ],
    },
    {
        path: '/management/track',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'student-profiles',
                loader: async ({ params }) => {
                    document.title = 'Student Management';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-student-profiles' });
                },
                element: <ManageAlumni key="track-student-profiles" />,
            },
            {
                path: 'student-career-preferences',
                loader: async ({ params }) => {
                    document.title = 'Student Management';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-student-career-preferences' });
                },
                element: <ManageAlumni key="track-student-career-preferences" />,
            },
            {
                path: 'alumni-profiles',
                loader: async ({ params }) => {
                    document.title = 'Alumni Management';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-alumni-profiles' });
                },
                element: <ManageAlumni key="track-alumni-profiles" />,
            },
            {
                path: 'alumni-education-preferences',
                loader: async ({ params }) => {
                    document.title = 'Alumni Management';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-alumni-education-preferences' });
                },
                element: <ManageAlumni key="track-alumni-education-preferences" />,
            },
            {
                path: 'alumni-engagement-with-students',
                loader: async ({ params }) => {
                    document.title = 'Alumni Management';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-alumni-engagement-with-students' });
                },
                element: <ManageAlumni key="track-alumni-engagement-with-students" />,
            },
            {
                path: 'alumni-highlights-with-students-engagement',
                loader: async ({ params }) => {
                    return ManageAlumniServiceEngage.pageLoader({
                        ...params,
                        pageName: 'track-alumni-highlights-with-students-engagement',
                    });
                },
                element: <ManageAlumniEngage key="track-alumni-highlights-with-students-engagement" />,
                //element: <ManageAlumni key="track-alumni-engagement-with-students" />,
            },
        ],
    },

    {
        path: '/management/:pageName',
        element: <StudentManagementReport />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/notification',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AlumniNotification pageTitle="Notifications for you" />,
            },
        ],
    },
    {
        path: '/email-verification/:id',
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AlumniEmailVerification />,
            },
        ],
    },
    {
        path: '/notification',
        element: <StandardLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AlumniNotification />,
            },
        ],
    },
    {
        path: '/email-verification/:id',
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AlumniEmailVerification />,
            },
        ],
    },
    {
        path: '/sso/:hash/:redirect_url?',
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AutoLogin />,
            },
        ],
    },
    {
        path: '/sso/:hash/:redirect_url/:redirect_user_id?',
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <AutoLogin />,
            },
        ],
    },
    {
        path: '/support',
        element: <EngagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'alumni-school',
                loader: async ({ params }) => {
                    return AlumniSchoolSupportService.pageLoader({ ...params, pageName: 'support-alumni-school' });
                },
                element: <AlumniSchoolSupport key="support-alumni-school" />,
            },
            {
                path: 'alumni-school/:schoolId/:programLevel',
                loader: async ({ params }) => {
                    return AlumniSchoolSupportService.pageLoader({ ...params, pageName: 'support-alumni-school' });
                },
                element: <AlumniSchoolSupport key="support-alumni-school" />,
            },
            {
                path: 'alumni-school-group/:schoolGroupId/:programLevel',
                loader: async ({ params }) => {
                    return AlumniSchoolSupportService.pageLoader({ ...params, pageName: 'support-alumni-school' });
                },
                element: <AlumniSchoolSupport key="support-alumni-school" />,
            },
            {
                path: 'alumni-school/:schoolGroupId/:schoolId/:programLevel',
                loader: async ({ params }) => {
                    return AlumniSchoolSupportService.pageLoader({ ...params, pageName: 'support-alumni-school' });
                },
                element: <AlumniSchoolSupport key="support-alumni-school" />,
            },

            {
                path: 'alumni-student-match',
                loader: async ({ params }) => {
                    return AlumniMatchStudentsService.pageLoader({ ...params, pageName: 'alumni-student-match' });
                },
                element: <AlumniMatchStudents />,
            },

            {
                path: 'create-new-user',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return CreateNewAlumniService.pageLoader({ ...params, pageName: 'create-new-user' });
                },
                element: <CreateNewAlumni />,
            },
            {
                path: 'all-school-summary',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-all-school-summary' });
                },
                element: <InternalAdminReport key="internal-admin-all-school-summary" />,
            },
            {
                path: 'all-alumni-report/:schoolId?',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-all-alumni-report' });
                },
                element: <InternalAdminReport key="internal-admin-all-alumni-report" />,
                children: [
                    {
                        path: ':profileCompletionStatusCode',
                        loader: async ({ params }) => {
                            return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-all-alumni-report' });
                        },
                        element: <InternalAdminReport key="internal-admin-all-alumni-report-profile-due" />,
                    },
                ],
            },
            {
                path: 'non-standard-colleges-report',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-user-entered-college' });
                },
                element: <InternalAdminReport key="internal-admin-user-entered-college" />,
            },
            {
                path: 'non-standard-exams-report',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-user-entered-exam' });
                },
                element: <InternalAdminReport key="internal-admin-user-entered-exam" />,
            },
            {
                path: 'benefits-report',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-benefits-report' });
                },
                element: <InternalAdminReport key="internal-admin-benefits-report" />,
            },
            {
                path: 'registration_profile_maintenance_report',
                loader: async ({ params }) => {
                    return InternalAdminReportService.pageLoader({ ...params, pageName: 'internal-admin-benefits-report' });
                },
                element: <InternalAdminReport key="internal-admin-benefits-report" />,
            },
            {
                path: 'student-profile-report',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return ManageAlumniService.pageLoader({ ...params, pageName: 'track-student-profiles' });
                },
                element: <ManageAlumni key="track-student-profiles" />,
            },
        ],
    },
    {
        path: 'institute',
        element: <ManagementLayout />,
        children: [
            {
                path: ':id',
                children: [
                    {
                        path: 'basic-info',
                        loader: async ({ params }) => {
                            document.title = 'Univariety';
                            return InstituteService.pageLoader({ ...params, pageName: 'admin-institute-create' });
                        },
                        element: <Institute />,
                    },
                    {
                        path: 'online-preference',
                        loader: async ({ params }) => {
                            document.title = 'Univariety';
                            return InstituteService.pageLoader({ ...params, pageName: 'institute-online-preference' });
                        },
                        element: <Institute />,
                    },
                    {
                        path: ':groupId/contacts',
                        loader: async ({ params }) => {
                            document.title = 'Univariety';
                            return ContactService.pageLoader({ ...params, pageName: 'admin-institute-contacts' });
                        },
                        element: <Contact />,
                    },
                ],
            },
            {
                path: 'listing',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return InstituteLisingService.pageLoader({
                        ...params,
                        pageName: 'institute-list',
                    });
                },
                element: <InstituteListing key="institute-list" />,
            },
        ],
    },
    {
        path: '/admin/track',
        element: <EngagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'alumni-highlights-with-students-engagement',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return AlumniEngagementService.pageLoader({
                        ...params,
                        pageName: 'track-alumni-highlights-with-students-engagement',
                    });
                },
                element: <AlumniEngagement key="track-alumni-highlights-with-students-engagement" />,
            },
        ],
    },
    {
        path: '/admin',
        element: <EngagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'alumni-interaction/:id/:instituteId',
                loader: async ({ params }) => {
                    document.title = 'Univariety';
                    return AlumniConversionsService.pageLoader({
                        ...params,
                        pageName: 'alumni-interaction',
                    });
                },
                element: <AlumniConversions key="alumni-interaction" />,
            },
        ],
    },
    {
        path: '/',
        children: [
            {
                path: 'valuable-conversation/:id',
                element: <AlumniSelectedConversions key="alumni-selected-interaction" />,
            },
        ],
    },

    {
        path: '/admin/track',
        element: <EngagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'alumni-highlights-with-students-engagement',
                loader: async ({ params }) => {
                    return AlumniEngagementService.pageLoader({
                        ...params,
                        pageName: 'track-alumni-highlights-with-students-engagement',
                    });
                },
                element: <AlumniEngagement key="track-alumni-highlights-with-students-engagement" />,
            },
        ],
    },
    {
        path: '/admin',
        element: <EngagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'alumni-interaction/:id',
                loader: async ({ params }) => {
                    return AlumniConversionsService.pageLoader({
                        ...params,
                        pageName: 'alumni-interaction',
                    });
                },
                element: <AlumniConversions key="alumni-interaction" />,
            },
        ],
    },
    {
        path: '/',
        children: [
            {
                path: 'valuable-conversation/:id',
                element: <AlumniSelectedConversions key="alumni-selected-interaction" />,
            },
        ],
    },

    {
        path: '/program',
        element: <ManagementLayout />,
        children: [
            {
                path: 'add-program',
                loader: async ({ params }) => {
                    document.title = 'Add Program';
                    return InstituteProgramService.pageLoader({
                        ...params,
                        pageName: 'add-program',
                    });
                },
                element: <CreateinstituteProgram />,
            },
            {
                path: 'edit-program/:id',
                loader: async ({ params }) => {
                    document.title = 'Edit Program';
                    return InstituteProgramService.pageLoader({
                        ...params,
                        pageName: 'add-program',
                    });
                },
                element: <CreateinstituteProgram />,
            },
            {
                path: 'add-outcome-info',
                loader: async ({ params }) => {
                    return InstituteProgramService.pageLoader({
                        ...params,
                        pageName: 'add-outcome-info',
                    });
                },
                element: <CreateinstituteProgram />,
            },
            {
                path: 'listing',
                loader: async ({ params }) => {
                    document.title = 'Programs';
                    return ProgramLisingService.pageLoader({
                        ...params,
                        pageName: 'program-list',
                    });
                },
                element: <ProgramListing key="program-list" />,
            },
        ],
    },
    {
        path: '/widget',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'listing',
                loader: async ({ params }) => {
                    document.title = 'Widgets';
                    return ManageWidgetsListService.pageLoader({
                        ...params,
                        pageName: 'widget-list',
                    });
                },
                element: <ManageWidgetsList key="widget-list" />,
            },
            {
                path: 'configure/:code/:id?',
                loader: async ({ params }) => {
                    document.title = 'Manage Widget';
                    return WidgetService.pageLoader({
                        ...params,
                        pageName: 'widget-configuration',
                    });
                },
                element: <WidgetConfiguration />,
            },
            {
                path: 'configuration/:code/:id?',
                loader: async ({ params }) => {
                    document.title = 'Manage Widget';
                    return WidgetService.pageLoader({
                        ...params,
                        pageName: 'widget-configuration',
                    });
                },
                element: <Configuration />,
            },
            {
                path: ':code/preview',
                loader: async ({ params }) => {
                    document.title = 'Widget Preview';
                    return WidgetService.pageLoader({
                        ...params,
                        pageName: 'widget-preview',
                    });
                },
                element: <WidgetConfiguration />,
            },  
            {
                path: 'configurelist/:code/:id?',
                loader: async ({ params }) => {
                    document.title = 'Manage Alumni List Widget';
                    return WidgetServiceList.pageLoader({
                        ...params,
                        pageName: 'widget-listing-configuration',
                    });
                },
                element: <WidgetConfigurationList />,
            },  
            {
                path: 'addguidingalumni/:code/:id?',
                loader: async ({ params }) => {
                    document.title = 'Manage Alumni List Widget';
                    return AddGuidingAlumniList.pageLoader({
                        ...params,
                        pageName: 'widget-listing-configuration',
                    });
                },
                element: <AddGuidingAlumni />,
            },  
            {
                path: 'editguidingalumni/:code/:id/:eid?',
                loader: async ({ params }) => {
                    document.title = 'Manage Alumni List Widget';
                    return AddGuidingAlumniList.pageLoader({
                        ...params,
                        pageName: 'widget-listing-configuration',
                    });
                },
                element: <AddGuidingAlumni />,
            },       
        ],
    },
    {
        path: '/domaincategory',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'list',
                loader: async ({ params }) => {
                    return DomainCategoryDataService.pageLoader({
                        ...params,
                        pageName: 'domain-data',
                    });
                },
                element: <DomainDetail key="domain-data" />,
            },
        ],
    },
    // {
    //     path: '/widget',
    //     element: <StandardLayout />,
    //     errorElement: <ErrorPage />,
    //     children: [
    //         {
    //             path: 'guiding-alumni',
    //             element: <AddGuidingAlumni />,
    //         },
    //         {
    //             path: 'guiding-alumni-preview',
    //             element: <GuidingAlumniPreview />,
    //         },
    //     ],
    // },
    {
        path: '/widgetview',
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'guiding-alumni-preview-frontend',
                element: <GuidingAlumniPreviewFrontend />,
            },
        ],
    },
    {
        path: '/profilemarkersnotes',
        element: <ManagementLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ':id/list',
                loader: async ({ params }) => {
                    return ProfileMarkerNotesService.pageLoader({
                        ...params,
                        pageName: 'profilemarker-notes',
                    });
                },
                element: <ProfileMarkerNotes key="profilemarker-notes" />,
            },
        ],
    },
    {
        element: <StandardLayout />,
        path: 'top-colleges/',
        children: [
            {
                path: '',
                loader: async ({ params }) => {
                    document.title = 'Top Colleges';
                    return CollegeService.pageLoader({
                        ...params,
                    });
                },
                element: <TopColleges />,
            },
        ],
    },
];

export default routes;
